<!--
 * @Author: gaojingran
 * @Date: 2021-04-15 13:18:02
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-08-11 15:05:58
 * @Description: 评价 & 查看评价
-->
<style lang="less" scoped>
.rate-modal {
  /deep/.ant-form-item {
    margin-bottom: 0px;
  }
  .note {
    border: 1px solid @border_color_default;
    border-radius: @border-radius_1;
    padding: 5px 10px;
    min-height: 100px;
  }
}
</style>

<template>
  <a-modal
    class="rate-modal"
    :width="580"
    :title="$t('orderInfo.btn_rate')"
    :maskClosable="false"
    :visible="visible"
    :footer="type === 1 ? null : undefined"
    @cancel="handleCancel"
  >
    <a-spin :spinning="loading">
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="type === 2 ? rules : {}"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 20 }"
      >
        <a-form-model-item prop="timeliness" :label="$t('orderInfo.rate_label_delivery')">
          <a-rate v-model="form.timeliness" :allowClear="false" :disabled="type === 1" :allowHalf="true" />
        </a-form-model-item>

        <a-form-model-item prop="quality" :label="$t('orderInfo.rate_label_quality')">
          <a-rate v-model="form.quality" :allowClear="false" :disabled="type === 1" :allowHalf="true" />
        </a-form-model-item>

        <a-form-model-item prop="response" :label="$t('orderInfo.rate_label_response')">
          <a-rate v-model="form.response" :allowClear="false" :disabled="type === 1" :allowHalf="true" />
        </a-form-model-item>

        <a-form-model-item prop="attitude" :label="$t('orderInfo.rate_label_manner')">
          <a-rate v-model="form.attitude" :allowClear="false" :disabled="type === 1" :allowHalf="true" />
        </a-form-model-item>

        <a-form-model-item prop="satisfaction" :label="$t('orderInfo.rate_label_satisfaction')">
          <a-rate v-model="form.satisfaction" :allowClear="false" :disabled="type === 1" :allowHalf="true" />
        </a-form-model-item>

        <a-form-model-item prop="score" :label="$t('orderInfo.rate_label_score')"> {{ score }} </a-form-model-item>

        <a-form-model-item prop="remark" :wrapper-col="{ span: 24 }" v-if="type === 2">
          <a-textarea
            class="mt-2"
            v-model="form.remark"
            :maxLength="200"
            :placeholder="$t('orderInfo.rate_holder')"
            :rows="5"
          />
        </a-form-model-item>
      </a-form-model>

      <div class="note mt-3 mb-1" v-if="type !== 2 && form.remark">
        <pre class="m0 text-wrap" v-html="form.remark"></pre>
      </div>
    </a-spin>
    <!-- footer -->
    <template v-slot:footer>
      <a-button @click="handleCancel">暂不评价</a-button>
      <a-button type="primary" @click="handleOk" :loading="loading"> {{ $t('submit') }} </a-button>
    </template>
  </a-modal>
</template>

<script>
const { validate } = window.$g

export default {
  name: 'RateModal',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    // 订单详情
    info: {
      type: Object,
      default: () => ({}),
    },
    // 评价详情
    rateInfo: {
      type: Object,
      default: () => ({}),
    },
    // 1 查看评价 2 评价
    type: {
      type: Number,
      validator(value) {
        return [1, 2].indexOf(value) !== -1
      },
    },
  },
  data() {
    return {
      loading: false,
      form: {
        attitude: 5,
        quality: 5,
        response: 5,
        timeliness: 5,
        satisfaction: 5,
        remark: '',
      },
      rules: {
        attitude: [validate.required],
        quality: [validate.required],
        response: [validate.required],
        timeliness: [validate.required],
        satisfaction: [validate.required],
        remark: [validate.size_200],
      },
    }
  },
  computed: {
    score() {
      const { attitude, quality, response, timeliness, satisfaction } = this.form
      const sum =
        Number(attitude ?? 0) +
        Number(quality ?? 0) +
        Number(response ?? 0) +
        Number(timeliness ?? 0) +
        Number(satisfaction ?? 0)
      const ava = sum / 5
      return ava
    },
  },
  watch: {
    visible(val) {
      if (val) {
        if (this.$is.Defined(this.rateInfo.quality)) {
          this.form = {
            ...this.form,
            ...this.rateInfo,
          }
        }
      } else {
        this.form = {
          attitude: 5,
          quality: 5,
          response: 5,
          timeliness: 5,
          satisfaction: 5,
          remark: '',
        }
        this.$refs.ruleForm.resetFields()
      }
    },
  },
  methods: {
    handleCancel() {
      if (this.loading) return
      this.$emit('update:visible', false)
    },

    handleOk() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true
            await this.$http('orderProcessProduceRemark', {
              ...this.form,
              orderId: this.info.id,
            })
            this.loading = false
            this.handleCancel()
          } catch (err) {
            this.loading = false
            this.$httpNotify(err)
          }
        }
      })
    },
  },
}
</script>

<template>
  <a-modal
    :width="580"
    :title="$t('orderInfo.btn_upload_finish')"
    :maskClosable="false"
    :visible="visible"
    @cancel="handleCancel"
    @ok="handleOk"
  >
    <template slot="footer">
      <a-button type="primary" @click="handleOk" :disabled="!form.finalDrafts.length > 0 || !form.finalDrafts[0].url">{{
        $t('submit')
      }}</a-button>
    </template>
    <a-form-model :model="form" :label-col="{ span: 4, offset: 0 }" :wrapper-col="{ span: 19 }">
      <a-form-model-item :label="$t('orderInfo.final_document')" required>
        <NeoUploadButton
          :title="$t('uploadButton.title')"
          v-model="form.finalDrafts"
          :showNote="false"
          :showFileList="true"
          :showIcon="true"
          accept=".zip,.xlsx,.xls,.doc,.docx,.txt,.pdf"
          :maxLength="1"
          :maxSize="400"
          @input="handleSubmitFinalDraft"
        />
      </a-form-model-item>
      <a-form-model-item :label="$t('orderInfo.final_document_show')">
        <a-textarea v-model="form.remark" :placeholder="$t('orderInfo.final_document_show')" :rows="6" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import NeoUploadButton from '@/components/upload/NeoUploadButton'

const { acceptMap } = window.$g

export default {
  name: 'UploadFinshDraft',
  components: {
    NeoUploadButton,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    orderId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      acceptMap,
      form: {
        finalDrafts: [],
        remark: undefined,
      },
    }
  },
  watch: {
    visible(val) {
      if (!val) {
        this.form.finalDrafts = []
      }
    },
  },
  methods: {
    // 上传终稿 没有类型限制
    async handleSubmitFinalDraft(data) {
      this.form.finalDrafts = data
      if (data && data.length > 0) {
        if (data[0].status == 'done') {
          this.form.finalDrafts = data
        }
        if (data[0].status == 'error') {
          this.form.finalDrafts = []
        }
      }
    },
    handleCancel() {
      this.form.finalDrafts = []
      this.$emit('close', false)
    },
    async handleOk() {
      try {
        const { name, url, size } = this.form.finalDrafts[0]
        await this.$http('submitFinalDraft', {
          finalDrafts: [
            {
              remark: this.form.remark ? this.form.remark : '',
              name,
              url,
              size,
            },
          ],
          orderId: this.orderId,
        })
        this.$emit('confirm')
      } catch (err) {
        this.$httpNotify(err)
      } finally {
        this.form.finalDrafts = []
        this.$emit('update:endDraftList', this.form.finalDrafts)
      }
      this.$emit('close', false)
    },
  },
}
</script>

<style lang="less" scoped>
.neo-upload-button {
  display: flex !important;
}
::v-deep {
  .file-tag-list {
    margin-top: 8px !important;
    margin-left: 20px !important;
  }
  .file-tag-item {
    display: flex !important;
  }
}
</style>

<!--
 * @Author: gaojingran
 * @Date: 2021-04-19 09:38:47
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-05-21 15:42:06
 * @Description: 报价单
-->

<template>
  <a-drawer :width="900" :visible="visible" @close="handleClose">
    <template v-slot:title>
      <a-space :size="10">
        <span>{{ $t('orderInfo.quote') }}</span>
        <NeoTag shape type="warning">{{ localeDict.orderStatus[Math.min(9, info.status)] | defined }}</NeoTag>
      </a-space>
    </template>
    <!-- 报价单 -->
    <Quotation v-if="visible" @close="handleClose" :info="info" />
  </a-drawer>
</template>

<script>
import NeoTag from '@/components/common/NeoTag'
import Quotation from './Quotation'

export default {
  name: 'QuotationDrawer',
  components: {
    NeoTag,
    Quotation,
  },
  props: {
    // 订单详情
    info: {
      type: Object,
      default: () => ({}),
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localeDict: window.$g.localeDict,
    }
  },
  methods: {
    handleClose() {
      this.$emit('update:visible', false)
    },
  },
}
</script>

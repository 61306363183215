<!--
 * @Author: gaojingran
 * @Date: 2021-04-15 14:06:24
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-08-05 16:54:19
 * @Description: 创建报价
-->
<style lang="less" scoped>
.create-quote-modal {
  .form-item {
    /deep/.ant-form-explain {
      margin-left: -20px;
    }
  }
  .table-item {
    /deep/.ant-form-item-control {
      height: auto;
      line-height: normal;
    }
    /deep/.ant-form-explain {
      margin-top: 5px;
    }
    /deep/.ant-input {
      border: none;
      text-align: center;
      padding: 0;
    }
  }
}
</style>

<template>
  <a-modal
    :width="900"
    :title="$t('orderInfo.btn_create_quote')"
    :maskClosable="false"
    :visible="visible"
    :footer="null"
    @cancel="handleCancel"
  >
    <a-spin :spinning="loading">
      <div class="create-quote-modal">
        <template v-if="oldProcess">
          <a-radio-group v-model="type">
            <a-radio-button :value="1" v-if="isShowSyncReport">
              {{ $t('orderInfo.quote_radio_sync') }}1
            </a-radio-button>
            <a-radio-button :value="2"> {{ $t('orderInfo.quote_radio_log') }}</a-radio-button>
          </a-radio-group>

          <div class="mt-4" v-if="type === 2">
            <a-space :size="5">
              <a-upload
                accept=".xlsx"
                :beforeUpload="handleBeforeUpload"
                :action="uploadAction"
                :showUploadList="false"
                :multiple="false"
                :disabled="loading || uploading"
                :withCredentials="true"
                :headers="uploadHeaders"
                @change="handleUploadChange"
              >
                <a-button type="primary" :loading="uploading">
                  <a-icon type="upload" v-if="!uploading" />{{ $t('uploadButton.title') }}
                </a-button>
              </a-upload>
              <a-button type="link" @click="handleDownloadTemplate">
                {{ $t('orderInfo.download_template') }} <a-icon type="download" />
              </a-button>
            </a-space>
          </div>

          <a-form-model class="mt-4" ref="form" :colon="true" :model="form">
            <a-table
              class="head-bg mb-4"
              size="middle"
              rowKey="targetCode"
              :key="type"
              :scroll="{ x: 1200 }"
              :data-source="type === 1 ? form.dataSourceOne : form.dataSourceTwo"
              :pagination="false"
              :bordered="true"
            >
              <a-table-column
                fixed="left"
                key="targetCode"
                align="center"
                :ellipsis="true"
                :width="180"
                :title="$t('orderInfo.table_lang')"
              >
                <template slot-scope="text">
                  <span>{{ $store.getters['app/getLangNameByCode'](text.targetCode) }}</span>
                </template>
              </a-table-column>

              <a-table-column-group>
                <span slot="title">{{ $t('orderInfo.table_matching') }}</span>
                <a-table-column v-for="k in tableColumn" :key="k" align="center" :title="k">
                  <template slot-scope="text, _, index">
                    <a-form-model-item
                      style="margin-bottom: 0px"
                      class="table-item"
                      :labelCol="{ span: 0 }"
                      :wrapperCol="{ span: 24 }"
                      :prop="`${type === 1 ? 'dataSourceOne.' : 'dataSourceTwo.'}` + index + `.${k}`"
                      :rules="[validate.required, validate.float_2]"
                    >
                      <a-input v-model="text[k]" size="small" @change="dispatchResize(), getWwcCount(index)" />
                    </a-form-model-item>
                  </template>
                </a-table-column>
              </a-table-column-group>

              <a-table-column
                fixed="right"
                key="wwc"
                :ellipsis="true"
                align="center"
                :width="100"
                :title="$t('orderInfo.table_wwc')"
              >
                <template slot-scope="text, _, index">
                  <span>{{ getWwcCount(index) }}</span>
                </template>
              </a-table-column>
            </a-table>

            <a-form-model-item
              class="form-item"
              prop="forecastWorkdays"
              labelAlign="left"
              style="margin-bottom: 0"
              :labelCol="{ span: 5 }"
              :wrapperCol="{ span: 18 }"
              :label="$t('orderInfo.finish_translate_time')"
              :rules="[validate.required]"
            >
              <a-input-number
                class="ml-1"
                style="width: 220px; margin-left: -20px"
                v-model="form.forecastWorkdays"
                :min="1"
                :max="999"
                :precision="0"
                :step="1"
              />
              <span class="ml-1">{{ $t('orderInfo.finish_translate_unit') }}</span>
            </a-form-model-item>
          </a-form-model>

          <a-button class="mt-4 mb-1" type="primary" @click="handleOk" :disabled="nextDisabled">
            {{ $t('next') }}
          </a-button>
        </template>

        <!-- 非翻译创译只显示工作日 rules校验规则-->
        <template v-else>
          <CreateQuoteModalSecondStep
            :info="info"
            :parent="parent"
            :oldProcess="oldProcess"
            @openQuoteDrawer="handleQuoteDrawer"
          />
        </template>
      </div>
    </a-spin>
  </a-modal>
</template>

<script>
import CreateQuoteModalSecondStep from './CreateQuoteModalSecondStep.vue'
import numeral from 'numeral'
import Cookies from 'js-cookie'
import { downloadUrl } from '@/utils/utils'
import ASMD from '@/utils/ASMD.js'

export default {
  name: 'CreateQuoteModal',
  props: {
    // 订单信息
    info: {
      type: Object,
      default: () => ({}),
    },
    parent: {
      type: Object,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    oldProcess: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    CreateQuoteModalSecondStep,
  },
  data() {
    return {
      loading: false,
      uploading: false,
      type: 1, // 1. 来自系统  2.来自上传文件
      uploadLog: [],
      validate: window.$g.validate,
      tableColumn: [],
      uploadHeaders: {
        token: Cookies.get(window.$g.storageKey.TOKEN) || '',
      },
      ratio: [], //折扣比率
      form: {
        forecastWorkdays: undefined,
        // type为1数据
        dataSourceOne: [],
        // type为2数据
        dataSourceTwo: [],
      },
    }
  },
  mounted() {},
  computed: {
    uploadAction() {
      return (
        window.SITE_CONFIG['apiURL'] + window.$g.api['orderWordReportUpload_no_method'] + `?orderId=${this.info.id}`
      )
    },
    nextDisabled() {
      if (
        (this.type === 1 && !this.form.dataSourceOne.length) ||
        (this.type === 2 && !this.form.dataSourceTwo.length)
      ) {
        return true
      } else {
        return false
      }
    },
    produceVersion() {
      return this.info.produceVersion
    },
    pinfo() {
      return this.parent.info
    },
    isShowSyncReport() {
      return this.produceVersion !== 0 && this.pinfo.jobId
    },
  },
  watch: {
    isShowSyncReport: {
      immediate: true,
      handler(v) {
        console.log('v :>> ', v)
        if (v) {
          this.type = 1
        } else {
          this.type = 2
        }
        console.log('this.type :>> ', this.type)
      },
    },
    visible(value) {
      if (value) {
        // fix 表头错位
        this.timer = setTimeout(() => {
          window.dispatchEvent(new Event('resize'))
        }, 600)
        // // 判断是否上传或者同步过
        // if (!this.syncFlag) {
        //   this.getWordReport()
        // }
        this.getWordReport()
        this.reloadReportRangeColumn()
        this.getWordReportRatio()
      } else {
        this.clearForm()
        if (this.isShowSyncReport) {
          this.type = 1
        } else {
          this.type = 2
        }
      }
    },
  },
  methods: {
    clearForm() {
      this.form.dataSourceOne = []
      this.form.dataSourceTwo = []
      this.form.forecastWorkdays = undefined
      this.form.dataSourceOne2 && (this.form.dataSourceOne2 = [])
      this.form.dataSourceTwo2 && (this.form.dataSourceTwo2 = [])
    },
    // 根据不同的产线版本加载不同的匹配区间
    reloadReportRangeColumn() {
      this.tableColumn = window.$g.wordReportRangeColumn.filter((item) => {
        if (item.produceVersion === this.info.produceVersion) {
          return item
        }
      })[0].range
    },
    clearTime() {
      this.timer && clearTimeout(this.timer)
    },

    handleCancel() {
      if (this.loading) return
      this.$emit('update:visible', false)
    },

    handleBeforeUpload(file) {
      const { name } = file
      const suffix = name.slice(name.lastIndexOf('.'))
      const sizeMB = (file.size / (1024 * 1024)).toFixed(2)

      if (suffix !== '.xlsx') {
        this.$message.error(this.$t('prompt.upload_illegal_format', { name }))
        return false
      }
      console.log(`sizeMB`, sizeMB)
      if (sizeMB > 5) {
        this.$message.error(this.$t('orderInfo.MB_less_5'))
        return false
      }
      this.uploading = true
    },

    dispatchResize() {
      // fix 固定列错位
      this.clearTime()
      this.timer = setTimeout(() => {
        window.dispatchEvent(new Event('resize'))
      }, 300)
    },

    // 创建报价
    handleOk() {
      if (this.loading) return
      const tableData = this.type === 1 ? this.form.dataSourceOne : this.form.dataSourceTwo
      // 原始数据
      const dataSource = this.type === 1 ? this.dataSourceOne : this.dataSourceTwo

      if (!tableData.length) {
        return this.$message.error(this.$t('orderInfo.without_word_report_message'))
      }
      this.$refs.form.validate(async (valid) => {
        this.dispatchResize()
        if (valid) {
          try {
            this.loading = true
            const wwcList = tableData.reduce((acc, val) => {
              const { targetCode } = val
              const item = Object.keys(val)
                .filter((k) => k !== 'targetCode')
                .map((k) => ({
                  id: dataSource.find((v) => v.targetCode === targetCode && v.discountRange === k)?.id,
                  baseCount: val[k],
                  discountRange: k,
                  targetCode: val.targetCode,
                }))
              acc = [...acc, ...item]
              return acc
            }, [])
            await this.$http('orderWordReportAdjust', {
              id: this.info.id,
              forecastWorkdays: this.form.forecastWorkdays,
              wwcList: wwcList,
            })
            this.loading = false
            this.$message.success(this.$t('prompt.save_success'))
            this.handleCancel()
            this.$emit('openQuoteDrawer')
          } catch (err) {
            console.log('错误', err)
            this.loading = false
            this.$httpNotify(err)
          }
        }
      })
    },

    // 模板上传
    handleUploadChange({ file }) {
      if (file.status === 'done') {
        this.uploading = false
        if (file.response.code !== 0) {
          return this.$message.error(file.response.msg)
        } else {
          this.getWordReportByTmp()
        }
      }
    },

    // 下载模板
    handleDownloadTemplate() {
      let url = ''
      if (this.info.produceVersion == 1) {
        url =
          // 'https://genplus-common.oss-cn-shanghai.aliyuncs.com/excel_template/%E7%BA%BF%E4%B8%8B%E6%97%A5%E5%BF%97.xlsx'
          'https://genplus-common.oss-cn-shanghai.aliyuncs.com/excel_template/%E7%BA%BF%E4%B8%8B%E6%97%A5%E5%BF%97%E6%A8%A1%E6%9D%BF-V1.0.xlsx'
      }
      if ([0, 2].includes(this.info.produceVersion)) {
        url =
          // 'https://genplus-common.oss-cn-shanghai.aliyuncs.com/excel_template/%E7%BA%BF%E4%B8%8B%E6%97%A5%E5%BF%97%E6%A8%A1%E6%9D%BF.xlsx'
          'https://genplus-common.oss-cn-shanghai.aliyuncs.com/excel_template/%E7%BA%BF%E4%B8%8B%E6%97%A5%E5%BF%97%E6%A8%A1%E6%9D%BF-V1.0.xlsx'
        // url =
        //   'https://genplus-common.oss-cn-shanghai.aliyuncs.com/excel_template/%E9%B2%B8%E8%AF%91-%E7%BA%BF%E4%B8%8B%E6%97%A5%E5%BF%97.xlsx'
      }

      downloadUrl(url)
    },

    // 统计wwc
    getWwcCount(idx) {
      const dataSource = this.type === 1 ? this.form.dataSourceOne : this.form.dataSourceTwo
      const count = this.tableColumn.reduce((acc, key) => {
        let ratio = this.ratio.filter((v) => v.discountRange === key)[0].discountValue
        const item = ASMD.multiply(Number(dataSource[idx][key]) || 0, ratio)
        // console.log('item :>> ', item)
        acc = ASMD.add(acc, item)
        // console.log('acc :>> ', acc)
        return acc
      }, 0)
      return count
    },

    /**
     * 表格数据转换
     * 1 按语言分类 整理数据
     * {
     *   zh-cn: [],
     *   en-us: []
     * }
     * 2 转换成真实数据
     * [
     *  {},
     *  {}
     * ]
     * */
    getTableSource(data) {
      const langMap = data.reduce((acc, val) => {
        if (acc[val.targetCode]) {
          acc[val.targetCode] = [...acc[val.targetCode], val]
        } else {
          acc[val.targetCode] = [val]
        }
        return acc
      }, {})
      return Object.entries(langMap).map(([lang, data]) => {
        const rangeMap = data.reduce((acc, val) => {
          acc[val.discountRange] = val.baseCount ? numeral(val.baseCount).format('0.[00]') : 0
          return acc
        }, {})
        return {
          targetCode: lang,
          ...rangeMap,
        }
      })
    },
    // 只用来返回统计的折扣字数
    getTableSourceDiscount(data) {
      const langMap = data.reduce((acc, val) => {
        if (acc[val.targetCode]) {
          acc[val.targetCode] = [...acc[val.targetCode], val]
        } else {
          acc[val.targetCode] = [val]
        }
        return acc
      }, {})
      return Object.entries(langMap).map(([lang, data]) => {
        const rangeMap = data.reduce((acc, val) => {
          acc[val.discountRange] = val.discountCount ? numeral(val.discountCount).format('0.[00]') : 0
          return acc
        }, {})
        return {
          targetCode: lang,
          ...rangeMap,
        }
      })
    },

    // 获取订单字数报告 from 上传文件
    async getWordReportByTmp() {
      try {
        this.loading = true
        const wordReport = await this.$http({
          key: 'orderWordReportInfo',
          params: {
            id: this.info.id,
          },
        })

        this.form.dataSourceTwo = this.getTableSource(wordReport)
        this.form.dataSourceTwo2 = this.getTableSourceDiscount(wordReport)
        // 用于寻找id
        this.dataSourceTwo = wordReport
        this.loading = false
      } catch (err) {
        this.loading = false
        this.$httpNotify(err)
      }
    },
    // 获取折扣利率
    async getWordReportRatio() {
      try {
        this.loading = true
        const ratio = await this.$http({
          key: 'orderWordReportRatio',
          params: {
            id: this.info.id,
          },
        })
        this.ratio = ratio

        this.loading = false
      } catch (err) {
        this.loading = false
        this.$httpNotify(err)
      }
    },

    // 获取订单字数报告 from 生产系统
    async getWordReport() {
      try {
        this.loading = true
        await this.$http('orderWordReportSync', { id: this.info.id })

        const wordReport = await this.$http({
          key: 'orderWordReportInfo',
          params: {
            id: this.info.id,
          },
        })
        this.form.dataSourceOne = this.getTableSource(wordReport)
        this.form.dataSourceOne2 = this.getTableSourceDiscount(wordReport)
        this.syncFlag = true
        // 用于寻找id
        this.dataSourceOne = wordReport
        this.loading = false
      } catch (err) {
        this.loading = false
        if (err.code === 120001009) {
          // 同步失败
          this.isShowSyncReport && this.$message.error(this.$t('orderInfo.no_word_count'))
        } else {
          this.$httpNotify(err)
        }
      }
    },

    // 石头定制，同rockwell
    handleQuoteDrawer() {
      this.handleCancel()
      this.$emit('openQuoteDrawer')
    },
  },
  beforeDestroy() {
    this.clearTime()
  },
}
</script>
